<template>
    <div id="course-list">
        <component-education-navbar></component-education-navbar>
        <div class="content">
            <div class="course-overview">
                <div class="lf">
<!--                    <img :src="require('@/assets/images/education/20200728-154902.png')" alt="">-->
                    <img :src="eduCoverPath + courseDetail.cover" alt="">
                </div>
                <div class="rf">
                    <h2>
                        {{ courseDetail.name }}
                        <span class="course-price">课程定价：¥{{ courseDetail.price }}</span>
                    </h2>
                    <div class="total">
                        <div class="lession-num">本课程共 {{ total }} 集</div>
                        <div class="views">
                            <i class="icon-views"></i>{{ courseDetail.views }}
                        </div>
                    </div>
                    <div class="lession-intr">
                        <h3>【课程介绍】</h3>
                        <p>{{ courseDetail.description }}</p>
                    </div>
                    <div class="btns">
                        <button class="learn" @click="learnHandle" v-if="isFree">立即学习</button>
                        <button class="buy" @click="buyHandle" v-else>立即学习</button>
                    </div>
                </div>
            </div>
            <div class="course">
                <div class="list">
                    <div class="topic">
                        <h2>课程列表</h2>
                        <span class="course-total">共{{ total }}集</span>
                    </div>
                    <ul v-if="coursewareList.length > 0">
                        <li v-for="(item, index) in coursewareList" :key="index" v-show="index < hidingNum">
<!--                            <span class="name" @click="toCourseDetail(item.id)">{{ item.name }}</span>-->
                            <span class="name">{{ item.name }}</span>
                            <div class="overview">
                                <div class="views">
                                    <i class="icon-views"></i> {{ item.views }}
                                </div>
                                <div class="comment">
                                    <i class="icon-comment"></i> {{ item.commentNum || 0 }}
                                </div>
                                <div class="arrow">
                                    <i class="icon-arrow-right"></i>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="empty" v-else>视频课件更新中，请稍后...</div>
                    <div class="check-all" v-if="coursewareList.length > 10">
                        <button>查看全部课程</button>
                    </div>
                </div>
                <div class="teacher-info">
                    <div class="top">
                        <h2>讲师介绍</h2>
                        <div class="teacher-msg">
                            <img :src="eduStaticPath + teacherInfo.headurl" alt="">
                            <div>
                                <h3>{{ teacherInfo.userContact }}</h3>
                                <p>{{ teacherInfo.title }}</p>
                            </div>
                        </div>
                        <div class="intr">
                            {{ teacherInfo.resume }}
                        </div>
                    </div>
                    <div class="other-course">
                        <h2>
                            <span>其他课程</span>
                            <router-link to="/online/list/OTQ%3D/courses">更多</router-link>
                        </h2>
                        <ul>
                            <li v-for="(item, index) in otherCourses" :key="index" v-show="index < 4">
                                <div class="" @click="toOtherCourse(item.id)">
                                    <img :src="eduCoverPath + item.cover" alt="">
                                    <h3>{{ item.name }}</h3>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <component-education-bottom :showScratchBg="true"></component-education-bottom>
        </div>
    </div>
</template>
<script>
    import ComponentEducationNavbar from '@/components/ComponentEducationNavbar'
    import ComponentEducationBottom from '@/components/ComponentEducationBottom'
    import {urls, staticPath, eduStaticPath, eduCoverPath, banbaoUrl} from '@/lib/common'
    export default {
        name: "CourseList",
        components: {
            ComponentEducationNavbar,
            ComponentEducationBottom
        },
        data() {
            return {
                staticPath,
                eduStaticPath,
                eduCoverPath,
                courseId: '',
                pageSize: 10,
                pageCurrent: 1,
                total: 0,
                teacherInfo: '',
                coursewareList: [],
                otherCourses: [],
                courseDetail: '',
                hidingNum: 10,
                isFree: false,

            };
        },
        methods: {
            getOtherCourseList() {
                const params = {
                    pageSize: this.pageSize,
                    pageCurrent: this.pageCurrent,
                    teacherId: this.courseDetail.teacherId
                }
                this.$http.get(urls.getCourseList, {params}).then(res => {
                    if (res.data.status === 200) {
                        const result = res.data.data.records;
                        this.otherCourses = result.filter(item => {
                            return item.id !== this.courseId
                        })
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            getTeacherInfo() {
                this.$http.get(urls.getTeacherInfo + this.courseId).then(res => {
                    if (res.data.status === 200) {
                        this.teacherInfo = res.data.data;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            getCourseInfo() {
                return new Promise((resolve, reject) => {
                    this.$http.get(urls.getCourseInfo + this.courseId).then(res => {
                        if (res.data.status === 200) {
                            this.courseDetail = res.data.data;
                        } else if (res.data.status !== 401 && res.data.status !== 106) {
                            this.$message.error(res.data.message)
                        }
                        resolve()
                    }).catch(err => {
                        reject(err)
                    })
                })
            },
            getCoursewareList() {
                const params = {
                    courseId: this.courseId
                }
                this.$http.get(urls.getCoursewareList, {params}).then(res => {
                    if (res.data.status === 200) {
                        this.coursewareList = res.data.data.records;
                        this.total = res.data.data.total;
                        if (this.courseDetail.price === 0) {
                            this.isFree = true
                        } else {
                            let count = 0;
                            for (let i in this.coursewareList) {
                                if (!this.coursewareList[i].isFree) {
                                    count++
                                }
                            }
                            count === this.coursewareList.length ? this.isFree = false : this.isFree = true;
                        }
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            toOtherCourse(id) {
                this.courseId = id;
                this.getCourseInfo().then(() => {
                    this.getCoursewareList();
                    this.getTeacherInfo();
                    this.getOtherCourseList();
                    this.$router.replace({
                        name: 'CourseList',
                        params: {
                            id: encodeURIComponent(btoa(id))
                        }
                    })
                })
            },
            buyHandle() {
                console.log('立即购买');
                if (!this.checkIsLogin()) {
                    this.$message.warning('请先登录!~')
                    sessionStorage.setItem('prevPath', JSON.stringify({
                        pathName: this.$route.name,
                        params: this.$route.params
                    }))
                    setTimeout(() => {
                        this.$router.push({
                            path: '/login'
                        })
                    }, 2000)
                }
                // this.$message.warning('该课程为付费课程，请购买后学习.')
            },
            learnHandle() {
                if (this.checkIsLogin()) {
                    this.$router.push({
                        name: 'CourseDetail',
                        params: {
                            courseId: this.$route.params.id
                        }
                    })
                } else {
                    this.$message.warning('请先登录!~')
                    sessionStorage.setItem('prevPath', JSON.stringify({
                        pathName: this.$route.name,
                        params: this.$route.params
                    }))
                    setTimeout(() => {
                        this.$router.push({
                            path: '/login'
                        })
                    }, 2000)
                }
            },
            toCourseDetail(id) {
                this.$router.push({
                    name: 'CourseDetail',
                    params: {
                        courseId: this.$route.params.id
                    },
                    query: {
                        n: encodeURIComponent(btoa(id))
                    }
                })
            },
            getCourseViews() {
                this.$http.get(urls.getCourseViews + this.courseId).then(res => {
                    console.log(res)
                })
            },
            checkIsLogin() {
                const token = this.$cookies.get('app.token', {domain: banbaoUrl, path: ''});
                // const userinfo = sessionStorage.getItem('userinfo');
                return token !== null && token !== undefined
            }
        },
        created() {
            if (this.$route.params && this.$route.params.id) {
                this.courseId = Number(atob(decodeURIComponent(this.$route.params.id)));
                this.getCourseInfo().then(() => {
                    this.getTeacherInfo()
                    this.getOtherCourseList()
                    this.getCoursewareList()
                    this.getCourseViews()
                })
            }
        }
    };
</script>
<style lang="less" scoped>
    #course-list {
        min-width: 1380px;
        min-height: 100vw;

        .content {
            height: 100%;
            box-sizing: border-box;
            background: #F2FCFF;
            .course-overview {
                padding: 10% 15%;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                background: #F2FCFF url("../../assets/images/education/course_bg.png") no-repeat center center;
                background-size: 100% 100%;
                >.lf {
                    width: 640px;
                    height: 400px;
                    img {
                        min-height: 200px;
                        height: 100%;
                        padding: 5px;
                        border-radius: 10px;
                        background-color: rgba(255, 255, 255, .5);
                    }
                }
                >.rf {
                    width: 50%;
                    color: #fff;
                    margin-left: 2vw;
                    position: relative;
                    box-sizing: border-box;
                    padding-bottom: 4vw;
                    >h2 {
                        font-size: 1.66vw;
                        line-height: 200%;
                        .course-price {
                            color: #F7B527;
                            font-size: 18px;
                        }
                    }
                    .total {
                        display: flex;
                        font-size: 14px;
                        .views {
                            margin-left: 20px;
                            .icon-views {
                                display: inline-block;
                                width: 20px;
                                height: 14px;
                                background: url("../../assets/images/icon_views_white.png") no-repeat center center;
                                background-size: 100%;
                                margin-right: 5px;
                            }
                        }
                    }
                    .lession-intr {
                        margin-top: 20px;
                        font-size: 18px;
                        line-height: 200%;
                        display: -webkit-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 4;
                    }
                    .btns {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        button {
                            background: url("../../assets/images/education/btn_bg_red.png") no-repeat center center;
                            background-size: 100%;
                            padding: 0.8vw 2.4vw;
                            margin-right: 10px;
                            font-weight: bold;
                            font-size: 18px;
                        }
                    }
                }
            }
            .course {
                padding: 5% 15% 6%;
                margin: auto;
                display: flex;
                background: #F2FCFF url("../../assets/images/education/courselist_bg.png") no-repeat center center;
                background-size: 100% 100%;
                .list {
                    width: 58%;
                    margin-right: 5%;
                    color: #333333;
                    .topic {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        font-size: 18px;
                        color: #989898;
                        h2 {
                            font-size: 36px;
                        }
                    }
                    ul {
                        margin-top: 20px;
                        li {
                            display: flex;
                            justify-content: space-between;
                            padding: 8px 0;
                            border-bottom: 1px dashed #D5F5FF;
                            .name {
                                font-size: 16px;
                                flex: 7;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                word-break: break-all;
                                white-space: nowrap;
                            }
                            .overview {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                flex: 3;
                                >div {
                                    margin-right: 6px;
                                    color: #A5A5A5;
                                    .icon-views {
                                        display: inline-block;
                                        width: 16px;
                                        height: 16px;
                                        background: url("../../assets/images/icon_views_dark.png") no-repeat center bottom;
                                        background-size: 100%;
                                    }
                                    .icon-comment {
                                        display: inline-block;
                                        width: 16px;
                                        height: 16px;
                                        background: url("../../assets/images/icon_comment.png") no-repeat center bottom;
                                        background-size: 100%;
                                        position: relative;
                                        top: 2px;
                                    }
                                    .icon-arrow-right {
                                        display: inline-block;
                                        width: 0.5vw;
                                        height: 0.5vw;
                                        border: 1px solid #dbdbdb;
                                        border-top: 0;
                                        border-right: 0;
                                        transform: rotate(-135deg);
                                    }
                                }
                            }
                        }
                    }
                    .empty {
                        text-align: center;
                        font-size: 18px;
                        color: #606060;
                        margin-top: 100px;
                    }
                    .check-all {
                        text-align: center;
                        margin: 2vw 0;
                        button {
                            background: #C6F2FF;
                            color: #0047BB;
                            padding: 0.8vw 2vw;
                            border-radius: 6px;
                            font-size: 18px;
                        }
                    }
                }
                .teacher-info {
                    width: 38%;
                    padding: 0 20px 20px;
                    display: flex;
                    flex-wrap: wrap;
                    .top {
                        width: 100%;
                        font-size: 18px;
                        h2 {
                            font-size: 36px;
                        }
                        .teacher-msg {
                            margin: 30px 0 20px;
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-end;
                            img {
                                width: 120px;
                                height: 120px;
                            }
                            >div {
                                margin-left: 20px;
                                h3 {
                                    font-size: 1.25vw;
                                    color: #333;
                                    margin-bottom: .6vw;
                                }
                                p {
                                    color: #a5a5a5;
                                }
                            }
                        }
                        .intr {
                            color: #3B3B3B;
                            line-height: 180%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 4;
                        }
                    }
                    .other-course {
                        width: 100%;
                        margin-top: 2vw;
                        border-top: 1px dashed #C6F2FF;
                        >h2 {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-end;
                            font-size: 18px;
                            padding: 20px 0;
                            a {
                                padding: 4px 10px;
                                border-radius: 2vw;
                                background: #00A66C;
                                color: #fff;
                                font-size: 0.83vw;
                                font-weight: normal;
                                &::after {
                                    content: '';
                                    display: inline-block;
                                    width: 6px;
                                    height: 6px;
                                    border: 1px solid #fff;
                                    border-right: 0;
                                    border-top: 0;
                                    transform: rotate(-135deg);
                                    position: relative;
                                    top: -2px;
                                }
                            }
                        }
                        ul {
                            display: flex;
                            flex-wrap: wrap;
                            li {
                                width: 48%;
                                text-align: center;
                                margin-right: 4%;
                                margin-bottom: 20px;
                                cursor: pointer;
                                &:nth-child(even) {
                                    margin-right: 0;
                                }
                                img {
                                    border-radius: 10px;
                                }
                                h3 {
                                    margin-top: 10px;
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #course-list{
            min-width: 320px;
            .content{
                .course-overview{
                    background-position: center top;
                    background-size:100% auto;
                    flex-direction: column;
                    padding: 100px 5.2% 8%;
                    &>.lf{
                        width: 100%;
                    }
                    &>.rf{
                        width: 100%;
                        color: #333;
                        &>h2{
                            .fontFn(20);
                        }
                        .total{
                            .fontFn(14);
                            .views{
                                position: relative;
                                top:-2px;
                                .fontFn(12);
                                .icon-views{
                                    background-image: url('../../assets/images/phone/icon_eye.png');
                                    position: relative;
                                    top: 2px;
                                }
                            }
                            .lession-num{
                                .fontFn(12);
                            }
                        }
                        .lession-intr{
                            .fontFn(16);
                        }
                        .btns{
                            position: relative;
                            button{
                                color:#fff;
                                .fontFn(16);
                                padding: 4% 8%;
                            }
                        }
                    }
                }
                .course{
                    background: none;
                    flex-direction: column;
                    padding: 5% 5.2%;
                    .list{
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: 30%;
                        padding: 6% 5.2% 10%;
                        position: relative;
                        background: #fff;
                        &::before{
                            position:absolute;
                            content:'';
                            display: block;
                            top:-80px;
                            left:0;
                            width: 100%;
                            height: 80px;
                            background: url('../../assets/images/phone/card_head_bg.png') no-repeat center bottom;
                            background-size: 100% auto;
                        }
                        .topic{
                            .fontFn(14);
                            h2{
                                .fontFn(20);
                            }
                        }
                        ul{
                            li{
                                padding: 5% 0;
                                .name{
                                    .fontFn(14);
                                    flex:6;
                                }
                                .overview{ 
                                    flex:4;
                                    justify-content:flex-end;
                                    .fontFn(14);
                                    &>div{
                                        margin-right: 0;
                                        margin-left: 10px;
                                        .icon-arrow-right{
                                            .fontFn(10,width);
                                            .fontFn(10,height);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .teacher-info{
                        width: 100%;
                        position: relative;
                        background: #fff;
                        &::before{
                            position:absolute;
                            content:'';
                            display: block;
                            top:-100px;
                            left:0;
                            width: 100%;
                            height: 100px;
                            background: url('../../assets/images/phone/card_head_bg2.png') no-repeat center bottom;
                            background-size: 100% auto;
                        }
                        .top{
                            h2{
                                .fontFn(20);
                            }
                            .teacher-msg{
                                &>div{
                                    h3{
                                        .fontFn(16);
                                    }
                                    p{.fontFn(14);}
                                }
                            }
                            .intr{
                                .fontFn(14);
                            }
                        }
                        .other-course{
                            h2{
                                .fontFn(16);
                                a{
                                    .fontFn(12);
                                    .fontFn(16,border-radius);
                                }
                            }
                            ul{
                                li{
                                    width: 100%;
                                    margin-right: 0;
                                    margin-bottom: 6%;
                                    h3{
                                        .fontFn(16);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>